import React, { useState } from "react";
import { ModuleProvider } from "./ModuleContext";
import Steps from "./Steps";
import Footer from "../components/Footer";

const Module = () => {
  const module = [
    {
      title: "Introduction au Luxe",
      description: `Ce module vous plonge dans l'univers fascinant du luxe. Vous découvrirez 
      les principes fondamentaux qui gouvernent ce secteur, en mettant l'accent sur ce qui rend chaque 
      expérience unique et mémorable. Préparez-vous à apprendre comment la notion de luxe dépasse 
      les simples biens matériels pour inclure une expérience personnalisée et exclusive. Vous comprendrez également 
      les valeurs fondamentales qui définissent le luxe, telles que l'élégance, la rareté, et l'attention aux détails.`,
    },
  ];

  const [isStarted, setIsStarted] = useState(false);

  const handleStart = () => {
    setIsStarted(true);
  };

  const handleBack = () => {
    setIsStarted(false);
  };

  return (
    <ModuleProvider>
      <div className="min-h-screen bg-[#121212] flex flex-col">
        <div className="flex-grow max-w-4xl mx-auto p-4 rounded-lg shadow-lg">
          <h1 className="text-3xl font-alientz text-white mt-6 mb-6">
            Module 1 : {module[0].title}
          </h1>

          {!isStarted ? (
            <div>
              <p className="text-gray-300 mb-4">{module[0].description}</p>
              <button
                onClick={handleStart}
                className="py-2 px-4 border-2 font-bold rounded-2xl hover:bg-white hover:text-black duration-300 mt-4 ml-2"
              >
                Commencer
              </button>
            </div>
          ) : (
            <Steps onBack={handleBack} />
          )}
        </div>

        <Footer />
      </div>
    </ModuleProvider>
  );
};

export default Module;
