import React, { useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown"; // Import pour le rendu Markdown
import Footer from "../components/Footer"; // Import du footer
import instructions from "./instructions"; // Import des instructions
import { useUser } from "@clerk/clerk-react"; // Import du hook Clerk

const Lumi = () => {
  const { user } = useUser(); // Récupérer l'utilisateur connecté via Clerk
  const [userInput, setUserInput] = useState("");
  const [aiResponse, setAiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchGeminiResponse = async (contentText) => {
    setLoading(true);
    setError(null);

    try {
      const fullInstructions = `
        ${instructions}

        Étudiant : Prénom : ${user?.firstName} | Nom : ${user?.lastName}.

		Message de l'étudiant :
      `;

      const response = await axios.post(
        "https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent",
        {
          contents: [
            {
              parts: [{ text: fullInstructions }, { text: contentText }],
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            key: process.env.REACT_APP_GEMINI_API_KEY,
          },
        },
      );

      if (
        response.data &&
        response.data.candidates &&
        response.data.candidates.length > 0 &&
        response.data.candidates[0].content &&
        response.data.candidates[0].content.parts &&
        response.data.candidates[0].content.parts.length > 0
      ) {
        const text = response.data.candidates[0].content.parts[0].text;
        setAiResponse(text);
      } else {
        setError("La réponse de Lumi ne contient pas la structure attendue.");
      }

      setLoading(false);
    } catch (error) {
      setError("Erreur lors de l'appel à Lumi");
      setLoading(false);
      console.error("Erreur API Gemini :", error);
    }
  };

  const handleSendMessage = () => {
    if (userInput.trim()) {
      fetchGeminiResponse(userInput);
      setUserInput("");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black text-gray-100">
      <div className="module-container max-w-4xl mx-auto p-8 bg-gray-850 w-full mb-16 mt-12 flex-grow">
        <h2 className="text-4xl font-alientz mb-8 text-center text-gradient">
          Posez toutes vos questions à Lumi
        </h2>

        <div className="input-section mb-8">
          <textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Posez une question ou demandez une information sur le luxe..."
            className="w-full h-40 p-5 bg-gray-800 border border-gray-700 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-300 placeholder-gray-500 transition-all shadow-md"
          ></textarea>
          <button
            onClick={handleSendMessage}
            disabled={loading}
            className="mt-6 w-full bg-indigo-600 hover:bg-indigo-700 font-alientz text-white p-3 rounded-xl shadow-md disabled:bg-gray-500 transition-all"
          >
            {loading ? "Chargement..." : "Envoyer"}
          </button>
        </div>

        <div className="ai-response">
          {error && <p className="text-red-500 text-center">{error}</p>}
          {aiResponse && (
            <div className="response-box mt-8 p-6 bg-gray-800 text-gray-200 rounded-2xl shadow-lg">
              <h3 className="font-bold text-xl mb-4 text-indigo-400">LUMI :</h3>
              {/* Affichage de la réponse avec Markdown */}
              <ReactMarkdown className="markdown-response prose prose-indigo">
                {aiResponse}
              </ReactMarkdown>
            </div>
          )}
        </div>

        <div className="instruction mt-8">
          <p className="text-sm text-center text-gray-500 italic">
            Lumi est là pour vous guider dans votre exploration du monde du
            luxe. Posez-lui toutes vos questions.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Lumi;
