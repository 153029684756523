import React, { useRef } from "react";
import { jsPDF } from "jspdf";
import { useUser } from "@clerk/clerk-react";
import Footer from "../components/Footer";

const Certificate = () => {
  const pdfRef = useRef(null);
  const { user } = useUser();

  const generatePDF = () => {
    const doc = new jsPDF("landscape", "px", "a4", true);
    doc.html(pdfRef.current, {
      callback: (doc) => {
        doc.save("certificat-leyzen.pdf");
      },
      x: 10,
      y: 10,
    });
  };

  if (!user) {
    return (
      <div className="flex flex-col min-h-screen bg-[#1f1f1f] justify-center items-center">
        <p className="text-white">Chargement des données utilisateur...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-[#1f1f1f] justify-center items-center overflow-x-hidden">
      {/* Section principale */}
      <div className="flex flex-col items-center justify-center pb-12 px-4 w-full">
        <h1 className="text-3xl md:text-4xl font-alientz text-center text-[#b98c50] mt-8 mb-8">
          Félicitations, {user.firstName}!
        </h1>

        <div
          ref={pdfRef}
          className="relative bg-gradient-to-b from-[#121212] to-[#1f1f1f] shadow-2xl rounded-2xl p-6 md:p-10 w-full max-w-[900px] h-auto flex flex-col items-center justify-between border-4 border-[#b98c50] mb-12"
        >
          {/* En-tête du certificat */}
          <div className="text-center space-y-2 mb-6 md:mb-12">
            <h2 className="text-3xl md:text-5xl font-alientz text-[#b98c50]">
              Certificat de Réussite
            </h2>
            <p className="text-sm md:text-lg text-gray-300">
              Un accomplissement remarquable
            </p>
          </div>

          {/* Corps du certificat */}
          <div className="text-center">
            <p className="text-sm md:text-lg text-gray-200">
              Ceci est remis à :
            </p>
            <h3 className="text-4xl md:text-6xl font-alientz text-[#b98c50] mt-2 md:mt-4">
              {user.firstName} {user.lastName}
            </h3>
            <p className="text-sm md:text-lg text-gray-200 mt-4 md:mt-6">
              Pour ses excellents résultats en tant que :
            </p>
            <p className="text-lg md:text-xl text-[#b98c50] font-semibold mt-2">
              Stratégiste en Fidélisation
            </p>
          </div>

          {/* Description supplémentaire */}
          <div className="mt-6 md:mt-12 text-center">
            <p className="text-xs md:text-md text-gray-300 mb-4">
              Le programme rigoureux et les projets réels ont permis à{" "}
              {user.firstName} d'atteindre un niveau d'excellence qui mérite
              d'être célébré. Cette certification représente des compétences
              exceptionnelles dans l'art de fidéliser la clientèle de luxe.
            </p>
          </div>

          {/* Signature et logo */}
          <div className="flex justify-between items-center w-full px-4 md:px-16 mt-6 md:mt-8">
            <div className="text-left text-gray-300">
              <p className="text-sm md:text-lg font-alientz">S. Idrissi</p>
              <p className="text-xs md:text-md text-gray-400 italic">
                CEO of Leyzen
              </p>
            </div>
            <div className="w-12 h-12 md:w-20 md:h-20">
              <img
                src="/favicon.png"
                alt="Logo Leyzen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>

        {/* Bouton PDF */}
        <button
          onClick={generatePDF}
          className="mt-2 px-6 md:px-8 py-2 md:py-3 bg-[#b98c50] text-white text-sm md:text-xl font-bold rounded-lg shadow-lg hover:bg-[#946f3e] transition duration-300"
        >
          Télécharger le Certificat
        </button>
      </div>

      {/* Section supplémentaire 1 */}
      <section className="w-full bg-[#121212] py-12">
        <div className="text-center text-gray-300 mr-8 ml-8">
          <h2 className="text-3xl font-alientz text-[#b98c50] mb-4">
            Pourquoi ce certificat ?
          </h2>
          <p className="text-lg mb-4">
            Ce certificat atteste des compétences acquises en matière de
            fidélisation de la clientèle de luxe, offrant à nos diplômés un
            avantage compétitif dans un secteur en constante évolution.
          </p>
          <p className="text-lg">
            Vous êtes maintenant prêt à prendre des responsabilités de haut
            niveau dans le domaine du luxe et à contribuer à l’élévation des
            standards de service client.
          </p>
        </div>
      </section>

      {/* Section supplémentaire 2 */}
      <section className="w-full bg-[#1f1f1f] py-12">
        <div className="text-center text-gray-300 mr-8 ml-8">
          <h2 className="text-3xl font-alientz text-[#b98c50] mb-4">
            Suivez votre parcours avec Leyzen
          </h2>
          <p className="text-lg mb-4">
            Grâce à la plateforme Leyzen, vous pouvez continuer à apprendre et à
            vous perfectionner tout au long de votre carrière. Rejoignez une
            communauté d'experts et d'innovateurs.
          </p>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Certificate;
