import React, { useCallback } from "react";
import { useModuleContext } from "./ModuleContext";
import Video from "./Video";
import Quiz from "./Quiz";
import Scenario from "./Scenario";
import Summary from "./Summary";
import ProgressIndicator from "./ProgressIndicator";

const Steps = ({ onBack }) => {
  const {
    currentStep,
    setCurrentStep,
    quizScore,
    setQuizScore,
    scenarioCompleted,
    setScenarioCompleted,
  } = useModuleContext();

  const handleRetryQuiz = useCallback(() => {
    setQuizScore(0);
    setCurrentStep(0);
  }, [setQuizScore, setCurrentStep]);

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const steps = [
    { name: "Vidéo", component: <Video /> },
    {
      name: "Quiz",
      component: (
        <Quiz
          onComplete={(score, passed) => {
            setQuizScore(score);
            if (passed) handleNextStep();
          }}
          onRetry={handleRetryQuiz}
        />
      ),
    },
    {
      name: "Scenario",
      component: (
        <Scenario
          onComplete={() => {
            setScenarioCompleted(true);
            handleNextStep();
          }}
        />
      ),
    },
    {
      name: "Summary",
      component: (
        <Summary
          quizScore={quizScore}
          totalQuestions={20}
          onRetryQuiz={handleRetryQuiz}
          onProceed={handleNextStep}
          onBack={() => setCurrentStep(0)}
        />
      ),
    },
  ];

  const handlePrevStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep, setCurrentStep]);

  const canProceed =
    currentStep === 0 ||
    (currentStep === 1 && quizScore >= Math.ceil(20 * 0.9)) ||
    (currentStep === 2 && scenarioCompleted);

  return (
    <div>
      <ProgressIndicator currentStep={currentStep} totalSteps={steps.length} />
      <div>{steps[currentStep].component}</div>

      <div className="mt-4 flex justify-between">
        {currentStep > 0 && currentStep !== 4 && (
          <button
            onClick={handlePrevStep}
            className="px-4 py-2 border-2 font-bold rounded-2xl hover:bg-white hover:text-black duration-300"
          >
            Retour
          </button>
        )}

        {canProceed && currentStep < steps.length - 2 && (
          <button
            onClick={handleNextStep}
            className="px-4 py-2 border-2 font-bold rounded-2xl hover:bg-white hover:text-black duration-300"
          >
            Continuer
          </button>
        )}

        {currentStep === 1 && quizScore < Math.ceil(20 * 0.9) && (
          <button
            onClick={handleRetryQuiz}
            className="px-4 py-2 border-2 font-bold rounded-2xl bg-red-500 text-white hover:bg-red-700 duration-300"
          >
            Réessayer
          </button>
        )}
      </div>
    </div>
  );
};

export default Steps;
