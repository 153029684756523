import React from "react";

const CardTitle = ({ children, className = "", ...props }) => {
  return (
    <h2 className={`text-xl font-alientz ${className}`} {...props}>
      {children}
    </h2>
  );
};

export default CardTitle;
