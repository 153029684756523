import React from "react";

const CardHeader = ({ children, className = "", ...props }) => {
  return (
    <div className={`p-4 bg-[#111111] ${className}`} {...props}>
      {children}
    </div>
  );
};

export default CardHeader;
