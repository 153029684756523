import React, { useState } from "react";
import { ModuleProvider } from "./ModuleContext";
import Steps from "./Steps";
import Footer from "../components/Footer";

const Module = () => {
  const module = [
    {
      title: "Vente et Persuasion",
      description: `Explorez les secrets de la vente dans le luxe. Ce module vous enseignera les techniques de persuasion 
  adaptées à un public exigeant. Vous apprendrez à mettre en valeur les caractéristiques uniques des produits et services 
  de luxe, à construire des relations de confiance et à conclure des ventes qui surpassent les attentes des clients. 
  Devenez un maître de la persuasion subtile et authentique.`,
    },
  ];

  const [isStarted, setIsStarted] = useState(false);

  const handleStart = () => {
    setIsStarted(true);
  };

  const handleBack = () => {
    setIsStarted(false);
  };

  return (
    <ModuleProvider>
      <div className="min-h-screen bg-[#121212] flex flex-col">
        <div className="flex-grow max-w-4xl mx-auto p-4 rounded-lg shadow-lg">
          <h1 className="text-3xl font-alientz text-white mt-6 mb-6">
            Module 5 : {module[0].title}
          </h1>

          {!isStarted ? (
            <div>
              <p className="text-gray-300 mb-4">{module[0].description}</p>
              <button
                onClick={handleStart}
                className="py-2 px-4 border-2 font-bold rounded-2xl hover:bg-white hover:text-black duration-300 mt-4 ml-2"
              >
                Commencer
              </button>
            </div>
          ) : (
            <Steps onBack={handleBack} />
          )}
        </div>

        <Footer />
      </div>
    </ModuleProvider>
  );
};

export default Module;
