import React, { useState, useEffect } from "react";
import { useUser, UserButton } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

function Dashboard() {
  const { user } = useUser();
  const [modules, setModules] = useState([]);
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);

  useEffect(() => {
    const savedProgress =
      JSON.parse(localStorage.getItem(`progress_${user?.id}`)) || {};

    const baseModules = [
      {
        id: 1,
        title: "1. Introduction au Luxe",
        description: "Découvrez l'univers du luxe et son histoire.",
      },
      {
        id: 2,
        title: "2. Communication Raffinée",
        description: "Maîtrisez les codes d'une communication élégante.",
      },
      {
        id: 3,
        title: "3. Personnalisation et Fidélisation",
        description: "Créez une expérience unique pour vos clients.",
      },
      {
        id: 4,
        title: "4. Conflits et Attentes",
        description: "Gérez efficacement les attentes et conflits clients.",
      },
      {
        id: 5,
        title: "5. Vente et Persuasion",
        description: "Développez vos compétences en persuasion.",
      },
      {
        id: 6,
        title: "6. Projet Final",
        description: "Réalisez un projet concret pour valider vos acquis.",
      },
    ];

    const initializedModules = baseModules.map((module) => ({
      ...module,
      progress: savedProgress[module.id] || 0,
    }));

    setModules(initializedModules);
  }, [user?.id]);

  const toggleModuleView = () => {
    setCurrentModuleIndex((prev) => (prev + 1) % modules.length);
  };

  return (
    <div className="min-h-screen bg-[#121212] text-white flex flex-col">
      {/* En-tête */}
      <header className="mt-4 mb-6 p-6 border-b border-gray-800 flex justify-between items-center">
        <div>
          <h1 className="text-4xl font-alientz">Bonjour {user.firstName},</h1>
          <p className="text-gray-400 text-sm mt-2">
            Continuez votre progression.
          </p>
        </div>
        <div className="scale-150">
          <UserButton />
        </div>
      </header>

      {/* Contenu principal */}
      <main className="flex-1 overflow-y-auto p-6">
        {/* Barre dynamique pour les modules */}
        <div className="flex justify-center items-center space-x-4 mb-6">
          <button
            onClick={toggleModuleView}
            className="text-white font-bold border-2 border-white px-4 py-2 rounded-full
              hover:bg-white hover:text-black duration-300"
          >
            Voir le module suivant
          </button>
        </div>

        {/* Affichage d'un seul module à la fois */}
        {modules.length > 0 && (
          <div className="p-8 bg-[#111111] shadow-white rounded-3xl shadow-sm hover:shadow-md hover:shadow-white">
            <h2 className="text-2xl font-alientz mb-4">
              {modules[currentModuleIndex].title}
            </h2>
            <p className="text-gray-400 mb-6">
              {modules[currentModuleIndex].description}
            </p>
            <div className="w-full bg-gray-700 h-2 rounded-full mb-4">
              <div
                className="bg-gray-300 h-2 rounded-full"
                style={{ width: `${modules[currentModuleIndex].progress}%` }}
              ></div>
            </div>
            <Link
              to={`/module/${modules[currentModuleIndex].id}`}
              className="inline-block px-6 py-2 border-2 border-white text-white rounded-full hover:bg-white hover:text-black duration-300"
            >
              Accéder au module
            </Link>
          </div>
        )}
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Dashboard;
