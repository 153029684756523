import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2000); // Redirige après 2 secondes

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="h-screen bg-[#0A0A0A] text-white font-alientz flex justify-center items-center">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-4">404 - Page Non Trouvée</h1>
        <p className="text-xl mb-4">
          Désolé, la page que vous recherchez n'existe pas.
        </p>
        <p className="text-gray-300 text-sm">
          Vous serez redirigé vers l'accueil dans quelques secondes...
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
