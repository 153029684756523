import React, { useState } from "react";
import { ModuleProvider } from "./ModuleContext";
import Steps from "./Steps";
import Footer from "../components/Footer";

const Module = () => {
  const module = [
    {
      title: "Personnalisation et Fidélisation",
      description: `Découvrez l'importance de la personnalisation dans la création d'expériences mémorables. 
  Ce module vous apprendra à comprendre les attentes individuelles de vos clients et à développer des stratégies 
  de fidélisation qui transforment chaque interaction en un moment unique. Apprenez comment surprendre vos clients 
  et les inciter à revenir grâce à une attention aux détails exceptionnelle.`,
    },
  ];

  const [isStarted, setIsStarted] = useState(false);

  const handleStart = () => {
    setIsStarted(true);
  };

  const handleBack = () => {
    setIsStarted(false);
  };

  return (
    <ModuleProvider>
      <div className="min-h-screen bg-[#121212] flex flex-col">
        <div className="flex-grow max-w-4xl mx-auto p-4 rounded-lg shadow-lg">
          <h1 className="text-3xl font-alientz text-white mt-6 mb-6">
            Module 3 : {module[0].title}
          </h1>

          {!isStarted ? (
            <div>
              <p className="text-gray-300 mb-4">{module[0].description}</p>
              <button
                onClick={handleStart}
                className="py-2 px-4 border-2 font-bold rounded-2xl hover:bg-white hover:text-black duration-300 mt-4 ml-2"
              >
                Commencer
              </button>
            </div>
          ) : (
            <Steps onBack={handleBack} />
          )}
        </div>

        <Footer />
      </div>
    </ModuleProvider>
  );
};

export default Module;
