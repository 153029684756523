import React from "react";
import Footer from "../components/Footer";

function Leaderboard() {
  return (
    <div className="min-h-screen bg-[#121212] text-gray-400 flex flex-col">
      {/* En-tête */}
      <header className="mt-4 mb-6 p-6 border-b border-gray-800 flex justify-between items-center">
        <div>
          <h1 className="text-4xl font-alientz text-white">Classement</h1>
          <p className="text-gray-500 text-sm mt-2">
            Découvrez les meilleurs performeurs de la plateforme.
          </p>
        </div>
      </header>

      {/* Podium */}
      <main className="flex-1 flex flex-col items-center justify-center p-6">
        <div className="flex items-end justify-center space-x-4 mb-12">
          {/* Deuxième place */}
          <div className="flex flex-col items-center">
            <div className="bg-gray-700 text-white w-16 h-16 rounded-full flex items-center justify-center text-xl font-bold">
              2
            </div>
            <p className="mt-4 text-white font-medium">Alex</p>
            <p className="text-sm text-gray-500">800 XP</p>
            <div className="bg-gray-800 h-16 w-12 mt-4 rounded-t-lg"></div>
          </div>

          {/* Première place */}
          <div className="flex flex-col items-center">
            <div className="bg-yellow-500 text-black w-20 h-20 rounded-full flex items-center justify-center text-2xl font-bold">
              1
            </div>
            <p className="mt-4 text-white font-medium">Saad</p>
            <p className="text-sm text-gray-500">1000 XP</p>
            <div className="bg-yellow-500 h-24 w-12 mt-4 rounded-t-lg"></div>
          </div>

          {/* Troisième place */}
          <div className="flex flex-col items-center">
            <div className="bg-gray-700 text-white w-16 h-16 rounded-full flex items-center justify-center text-xl font-bold">
              3
            </div>
            <p className="mt-4 text-white font-medium">Chris</p>
            <p className="text-sm text-gray-500">700 XP</p>
            <div className="bg-gray-800 h-12 w-12 mt-4 rounded-t-lg"></div>
          </div>
        </div>

        {/* Liste des autres participants */}
        <div className="w-full max-w-md">
          <h2 className="text-xl font-medium text-white mb-4">
            Autres participants
          </h2>
          <ul className="space-y-4">
            {[
              { name: "Taylor", xp: 600 },
              { name: "Jordan", xp: 500 },
              { name: "Casey", xp: 400 },
            ].map((user, index) => (
              <li
                key={index}
                className="flex justify-between items-center p-4 bg-gray-800 rounded-lg hover:bg-gray-700 transition duration-300"
              >
                <span className="text-white font-medium">{user.name}</span>
                <span className="text-sm text-gray-400">{user.xp} XP</span>
              </li>
            ))}
          </ul>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Leaderboard;
