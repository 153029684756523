import React, { useState, useCallback } from "react";
import { Button } from "../components/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";

const scenarios = [
  {
    question:
      "Vous recevez un nouveau client VIP dans votre boutique de luxe. Il semble pressé mais veut découvrir vos services. Que faites-vous ?",
    options: [
      {
        text: "L'accueillir rapidement mais chaleureusement",
        outcome: "Le client est intrigué",
        nextStep: 1,
      },
      {
        text: "Lui donner une brochure et le laisser explorer seul",
        outcome: "Le client se sent négligé",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Le client demande des produits exclusifs non disponibles en boutique. Que proposez-vous ?",
    options: [
      {
        text: "Offrir une consultation privée pour une commande sur mesure",
        outcome: "Le client est impressionné",
        nextStep: 2,
      },
      {
        text: "Suggérer d'attendre un futur arrivage",
        outcome: "Le client semble déçu",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Lors de la consultation, le client partage des préférences très spécifiques. Que faites-vous ?",
    options: [
      {
        text: "Prendre des notes détaillées et confirmer les attentes",
        outcome: "Le client se sent compris",
        nextStep: 3,
      },
      {
        text: "Offrir des suggestions génériques",
        outcome: "Le client perd confiance",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Vous découvrez qu'un produit commandé par le client ne pourra pas être livré à temps. Que faites-vous ?",
    options: [
      {
        text: "Prévenir immédiatement et proposer une alternative de valeur",
        outcome: "Le client apprécie votre transparence",
        nextStep: 4,
      },
      {
        text: "Attendre que le client remarque le retard",
        outcome: "Le client est frustré",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Le client accepte votre alternative, mais souhaite une touche plus personnalisée. Que proposez-vous ?",
    options: [
      {
        text: "Ajouter un détail unique en cadeau",
        outcome: "Le client est ravi",
        nextStep: 5,
      },
      {
        text: "Maintenir l'offre standard",
        outcome: "Le client est satisfait, mais sans plus",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Lors de la livraison, un petit défaut est signalé par le client. Comment réagissez-vous ?",
    options: [
      {
        text: "Proposer immédiatement un remplacement ou une réparation",
        outcome: "Le client admire votre réactivité",
        nextStep: 6,
      },
      {
        text: "Minimiser l'importance du défaut",
        outcome: "Le client est contrarié",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Le client souhaite organiser un événement privé avec votre marque. Que suggérez-vous ?",
    options: [
      {
        text: "Créer un événement exclusif avec des expériences uniques",
        outcome: "Le client est enchanté",
        nextStep: 7,
      },
      {
        text: "Proposer une visite standard de votre boutique",
        outcome: "Le client trouve cela banal",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Pendant l'événement, un invité important a une demande imprévue. Que faites-vous ?",
    options: [
      {
        text: "Adapter immédiatement pour répondre à la demande",
        outcome: "L'invité est impressionné, et le client aussi",
        nextStep: 8,
      },
      {
        text: "Expliquer pourquoi ce n'est pas possible",
        outcome: "Le client est embarrassé",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Après l'événement, le client exprime sa gratitude. Comment capitalisez-vous sur ce moment ?",
    options: [
      {
        text: "Envoyer un cadeau personnalisé avec une note de remerciement",
        outcome: "Le client devient un ambassadeur fidèle",
        nextStep: 9,
      },
      {
        text: "Ne rien faire et passer au prochain client",
        outcome: "Le client apprécie, mais sans plus",
        nextStep: null,
      },
    ],
  },
  {
    question:
      "Le client recommande vos services à ses amis influents. Que proposez-vous pour renforcer cette relation ?",
    options: [
      {
        text: "Créer un programme de fidélité exclusif pour ses proches",
        outcome: "Votre réseau s'agrandit et le client est satisfait",
        nextStep: 10,
      },
      {
        text: "Rester sur une relation professionnelle classique",
        outcome: "Le client reste satisfait mais distant",
        nextStep: null,
      },
    ],
  },
];

const Scenario = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [isIncorrect, setIsIncorrect] = useState(false);

  const handleOptionSelect = useCallback(
    (nextStep, outcome) => {
      setFeedback(outcome);

      if (nextStep !== null) {
        setCurrentStep(nextStep);
        setIsIncorrect(false);
      } else {
        setIsIncorrect(true);
      }

      if (nextStep === scenarios.length) {
        onComplete();
      }
    },
    [onComplete],
  );

  if (currentStep >= scenarios.length) {
    return (
      <Card>
        <CardContent>
          <p className="text-green-600">
            Félicitations, vous avez terminé le scénario !
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Scénario : Étape {currentStep + 1}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-xl mb-4">{scenarios[currentStep].question}</p>
        {feedback && (
          <p
            className={`mt-4 ${isIncorrect ? "text-red-600" : "text-green-600"}`}
          >
            {feedback}
          </p>
        )}
      </CardContent>
      <CardFooter className="flex flex-col gap-2">
        {scenarios[currentStep].options.map(
          ({ text, outcome, nextStep }, index) => (
            <Button
              key={index}
              onClick={() => handleOptionSelect(nextStep, outcome)}
              className={`w-full ${isIncorrect && nextStep === null ? "bg-red-500 hover:bg-red-600" : ""}`}
            >
              {text}
            </Button>
          ),
        )}
      </CardFooter>
    </Card>
  );
};

export default Scenario;
