import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-[#121212] w-full rounded-t-3xl border-black border-2 py-6 px-4 text-gray-400 text-center">
      {/* Conteneur principal avec flex-wrap pour les petits écrans */}
      <div className="flex flex-wrap justify-center items-center gap-4 md:gap-8">
        <Link
          to="/dashboard"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          🏠 Accueil
        </Link>
        <Link
          to="/lumi"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          💬 Lumi
        </Link>
        <Link
          to="/portfolio"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          📁 Portfolio
        </Link>
        <Link
          to="/ligue"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          🏆 Classement
        </Link>
        <Link
          to="/certificate"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          🎓 Certificat
        </Link>
        <Link
          to="/help"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          ❓ Centre d'aide
        </Link>
      </div>

      {/* Texte en dessous */}
      <p className="mt-4 text-xs text-white opacity-90 font-bold">
        {new Date().getFullYear()} LEYZEN&#8482;. Tous droits réservés.
      </p>
    </footer>
  );
}

export default Footer;
