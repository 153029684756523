import React, { createContext, useContext, useState } from "react";

const ModuleContext = createContext(undefined);

export const ModuleProvider = ({ children }) => {
  const [quizScore, setQuizScore] = useState(0);
  const [scenarioCompleted, setScenarioCompleted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <ModuleContext.Provider
      value={{
        quizScore,
        setQuizScore,
        scenarioCompleted,
        setScenarioCompleted,
        currentStep,
        setCurrentStep,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export const useModuleContext = () => {
  const context = useContext(ModuleContext);
  if (context === undefined) {
    throw new Error("useModuleContext must be used within a ModuleProvider");
  }
  return context;
};
