import React from "react";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { frFR } from "@clerk/localizations";
import { dark } from "@clerk/themes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import SignInPage from "./pages/SignInPage";
import Dashboard from "./pages/Dashboard";
import Module1 from "./modules/Module1";
import Module2 from "./modules/Module2";
import Module3 from "./modules/Module3";
import Module4 from "./modules/Module4";
import Module5 from "./modules/Module5";
import Module6 from "./modules/Module6";
import Lumi from "./pages/Lumi";
import Portfolio from "./pages/Portfolio";
import Leaderboard from "./pages/Leaderboard";
import Certificate from "./pages/Certificate";
import Help from "./pages/Help";
import NotFoundPage from "./pages/NotFoundPage";
import clerkConfig from "./clerk/clerkConfig";
import { Analytics } from "@vercel/analytics/react";
import ScrollToTop from "./components/ScrollToTop";

// Composant pour les transitions de page
const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

const AppContent = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <ScrollToTop />
      {/* Gestion des routes avec animation */}
      <Routes location={location} key={location.pathname}>
        {/* Page de connexion sur la racine */}
        <Route
          path="/"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <RedirectToDashboard />
                </SignedIn>
                <SignedOut>
                  <SignInPage />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        {/* Tableau de bord spécifique à chaque utilisateur */}
        <Route
          path="/dashboard"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Dashboard />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/lumi"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Lumi />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/module/1"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Module1 />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/module/2"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Module2 />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/module/3"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Module3 />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/module/4"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Module4 />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/module/5"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Module5 />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/module/6"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Module6 />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/portfolio"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Portfolio />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/ligue"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Leaderboard />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/help"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Help />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/certificate"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Certificate />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        {/* Gestion des routes non définies */}
        <Route
          path="*"
          element={
            <PageTransition>
              <NotFoundPage />
            </PageTransition>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  return (
    <ClerkProvider
      publishableKey={clerkConfig.api}
      appearance={{ baseTheme: dark }}
      localization={frFR}
      navigate={(to) => (window.location.href = to)}
    >
      <Router>
        <AppContent />
        <Analytics />
      </Router>
    </ClerkProvider>
  );
};

// Composant séparé pour gérer la redirection avec useUser
const RedirectToDashboard = () => {
  return <Navigate to={`/dashboard`} replace />;
};

const RedirectToSignIn = () => {
  return <Navigate to="/" replace />;
};

export default App;
