import React from "react";

const Video = ({ videoUrl = "https://www.youtube.com/embed/7J9nopUaLkc" }) => {
  return (
    <div className="text-center">
      <p className="mb-4 text-gray-300">
        Regardez cette vidéo pour découvrir les bases du module.
      </p>
      <div className="relative mb-4" style={{ paddingBottom: "56.25%" }}>
        <iframe
          src={videoUrl}
          title="Vidéo introductive"
          className="absolute top-0 left-0 w-full h-full rounded-xl"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
