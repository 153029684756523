import React, { useState, useCallback, memo } from "react";
import { Button } from "../components/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";

const questions = [
  {
    question: "Qu'est-ce qui définit le luxe ?",
    options: ["Accessibilité", "Exclusivité"],
    correct: "Exclusivité",
  },
  {
    question: "Quelle est une valeur clé du luxe ?",
    options: ["Rapidité", "Élégance"],
    correct: "Élégance",
  },
  {
    question: "Le luxe est-il uniquement matériel ?",
    options: ["Oui", "Non"],
    correct: "Non",
  },
  {
    question: "Comment fidéliser un client ?",
    options: ["Prix bas", "Expérience personnalisée"],
    correct: "Expérience personnalisée",
  },
  {
    question: "Un exemple de marque de luxe est :",
    options: ["Zara", "Hermès"],
    correct: "Hermès",
  },
  {
    question: "Un client insatisfait est une opportunité pour :",
    options: ["Apprendre et s'améliorer", "Ignorer"],
    correct: "Apprendre et s'améliorer",
  },
  {
    question: "Une relation client dans le luxe repose sur :",
    options: ["Confiance", "Rendement"],
    correct: "Confiance",
  },
  {
    question: "Qu'est-ce qu'un service VIP ?",
    options: ["Un service standard", "Un service personnalisé"],
    correct: "Un service personnalisé",
  },
  {
    question: "Le luxe se concentre sur :",
    options: ["Quantité", "Qualité"],
    correct: "Qualité",
  },
  {
    question: "Une expérience luxueuse peut inclure :",
    options: ["Simplicité", "Attention aux détails"],
    correct: "Attention aux détails",
  },
  {
    question: "Un client insatisfait dans le luxe doit être :",
    options: ["Négligé", "Écouté et compris"],
    correct: "Écouté et compris",
  },
  {
    question: "La communication dans le luxe est souvent :",
    options: ["Aggressive", "Subtile"],
    correct: "Subtile",
  },
  {
    question: "Qu'est-ce que l'exclusivité dans le luxe ?",
    options: ["Produits accessibles à tous", "Produits rares et recherchés"],
    correct: "Produits rares et recherchés",
  },
  {
    question: "Le storytelling dans le luxe sert à :",
    options: ["Vendre rapidement", "Créer un lien émotionnel"],
    correct: "Créer un lien émotionnel",
  },
  {
    question: "La gestion des attentes des clients est-elle cruciale ?",
    options: ["Oui", "Non"],
    correct: "Oui",
  },
  {
    question: "Quel est le rôle d'un stratège en fidélisation ?",
    options: ["Vendre des produits", "Créer des relations durables"],
    correct: "Créer des relations durables",
  },
  {
    question: "Le marketing dans le luxe repose sur :",
    options: ["Promotions fréquentes", "Création de désir"],
    correct: "Création de désir",
  },
  {
    question: "Une offre exclusive devrait être :",
    options: ["Personnalisée", "Générique"],
    correct: "Personnalisée",
  },
  {
    question: "Quelle est l'importance d'une bonne formation dans le luxe ?",
    options: ["Facultative", "Indispensable"],
    correct: "Indispensable",
  },
  {
    question: "La fidélité d'un client dans le luxe est souvent motivée par :",
    options: ["Prix réduits", "Qualité et expérience"],
    correct: "Qualité et expérience",
  },
];

const Quiz = memo(({ onComplete, onRetry }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  const handleAnswer = useCallback(
    (selectedOption) => {
      const isCorrect = selectedOption === questions[currentQuestion].correct;
      if (isCorrect) setScore((prevScore) => prevScore + 1);

      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      } else {
        const finalScore = score + (isCorrect ? 1 : 0);
        const passed = finalScore >= Math.ceil(questions.length * 0.9);
        onComplete(finalScore, passed);
      }
    },
    [currentQuestion, score, onComplete],
  );

  const handleRetry = useCallback(() => {
    setCurrentQuestion(0);
    setScore(0);
    onRetry();
  }, [onRetry]);

  if (currentQuestion >= questions.length) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Quiz terminé</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            Votre score : {score}/{questions.length}
          </p>
        </CardContent>
        <CardFooter>
          <Button onClick={handleRetry}>Réessayer</Button>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Question {currentQuestion + 1}</CardTitle>
      </CardHeader>
      <CardContent>
        <p>{questions[currentQuestion].question}</p>
      </CardContent>
      <CardFooter className="flex flex-col gap-2">
        {questions[currentQuestion].options.map((option, index) => (
          <Button
            key={index}
            onClick={() => handleAnswer(option)}
            className="w-full"
          >
            {option}
          </Button>
        ))}
      </CardFooter>
    </Card>
  );
});

export default Quiz;
