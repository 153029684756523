import React from "react";

const ProgressIndicator = ({ currentStep, totalSteps }) => {
  const progress = ((currentStep + 1) / totalSteps) * 100; // Calcul de la progression en pourcentage

  return (
    <div className="mb-4">
      <div className="flex justify-between mb-2">
        <span>
          Étape {currentStep + 1} sur {totalSteps}
        </span>
        <span>{Math.round(progress)}%</span>
      </div>

      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-blue-500 h-2.5 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
