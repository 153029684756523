import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { FaEnvelope, FaPhoneAlt, FaGlobe } from "react-icons/fa"; // Import des icônes
import Footer from "../components/Footer";

const Portfolio = () => {
  const { user } = useUser();

  if (!user) {
    return <p className="text-center mt-10 text-white">Chargement...</p>;
  }

  const fullName = user.fullName || "Nom Prénom";
  const email = user.emailAddresses[0]?.emailAddress || "Non Renseigné";
  const phoneNumber = user.phoneNumbers[0]?.phoneNumber || "Non Renseigné";
  const profileImage = user.imageUrl || "https://via.placeholder.com/150";

  return (
    <div className="bg-[#111111] min-h-screen text-white">
      <div className="max-w-4xl mx-auto p-6">
        {/* Bouton Retour tableau de bord */}
        <Link
          to="/dashboard"
          className="border-2 border-white text-white font-bold py-2 px-4 rounded-full hover:bg-white hover:text-black transition duration-300 mb-6"
        >
          retour
        </Link>

        {/* Photo et Nom */}
        <div className="text-center mb-12">
          <img
            src={profileImage}
            alt="Profil"
            className="w-40 h-40 rounded-full border-4 border-black shadow-md shadow-white mx-auto"
          />
          <h1 className="text-5xl font-alientz mt-4 tracking-wide">
            {fullName}
          </h1>
        </div>

        {/* À propos de moi */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold uppercase tracking-wide mb-4">
            À propos de moi
          </h2>
          <p className="text-lg leading-relaxed">
            Étudiant passionné par les technologies de pointe, je me consacre à
            l'apprentissage des métiers du futur et à la création de solutions
            innovantes pour le secteur du luxe.
          </p>
        </section>

        {/* Centres d'intérêts */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold uppercase tracking-wide mb-4">
            Centres d'intérêts
          </h2>
          <ul className="list-disc list-inside space-y-2">
            <li>Technologies et innovation</li>
            <li>Marketing digital</li>
            <li>Relation client et fidélisation</li>
            <li>Développement personnel</li>
          </ul>
        </section>

        {/* Compétences */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold uppercase tracking-wide mb-4">
            Compétences
          </h2>
          <div className="space-y-4">
            <div>
              <p>Marketing Digital</p>
              <div className="w-full bg-gray-700 h-2 rounded-full mt-1">
                <div
                  className="bg-white h-2 rounded-full"
                  style={{ width: "80%" }}
                ></div>
              </div>
            </div>
            <div>
              <p>Communication</p>
              <div className="w-full bg-gray-700 h-2 rounded-full mt-1">
                <div
                  className="bg-white h-2 rounded-full"
                  style={{ width: "90%" }}
                ></div>
              </div>
            </div>
            <div>
              <p>Relation Client</p>
              <div className="w-full bg-gray-700 h-2 rounded-full mt-1">
                <div
                  className="bg-white h-2 rounded-full"
                  style={{ width: "75%" }}
                ></div>
              </div>
            </div>
          </div>
        </section>

        {/* Langues */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold uppercase tracking-wide mb-4">
            Langues
          </h2>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <FaGlobe className="inline-block mr-2 text-white" />
              Français - Langue maternelle
            </li>
            <li>
              <FaGlobe className="inline-block mr-2 text-white" />
              Anglais - Courant
            </li>
            <li>
              <FaGlobe className="inline-block mr-2 text-white" />
              Espagnol - Intermédiaire
            </li>
          </ul>
        </section>

        {/* Contact */}
        <section className="mb-12">
          <h2 className="text-2xl font-bold uppercase tracking-wide mb-4">
            Contact
          </h2>
          <p>
            <FaEnvelope className="inline-block mr-2" />
            Email :{" "}
            <a href={`mailto:${email}`} className="underline">
              {email}
            </a>
          </p>
          <p>
            <FaPhoneAlt className="inline-block mr-2" />
            Téléphone : {phoneNumber}
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
