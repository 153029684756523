import React from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "../components/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";

const Summary = ({
  quizScore,
  totalQuestions,
  onRetryQuiz,
  onProceed,
  onBack,
}) => {
  const isQuizPassed = quizScore >= Math.ceil(totalQuestions * 0.9);

  const { userId } = useParams();

  return (
    <Card className="bg-black">
      <CardHeader>
        <CardTitle className="text-3xl font-bold text-center">
          Résumé du Module
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-lg mb-6">
          Félicitations pour avoir terminé ce module ! Voici un résumé de vos
          apprentissages et de vos performances.
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold  -800 mb-3">
            Points clés abordés
          </h2>
          <ul className="list-disc pl-6  -700">
            <li>Les principes fondamentaux du luxe et de sa communication.</li>
            <li>Comment personnaliser l'expérience client pour fidéliser.</li>
            <li>
              Stratégies pour gérer les attentes et résoudre les conflits.
            </li>
            <li>
              Les valeurs clés comme l'élégance, la qualité et l'exclusivité.
            </li>
            <li>La création d'une expérience mémorable pour chaque client.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">Vos performances</h2>
          <p>
            Vous avez obtenu un score de{" "}
            <span className="font-bold underline">
              {quizScore}/{totalQuestions}
            </span>{" "}
            au quiz.
          </p>
          {isQuizPassed ? (
            <p className="mt-2 text-green-600 font-semibold">
              Bravo, vous avez réussi le quiz ! Vous êtes prêt(e) à passer au
              prochain module.
            </p>
          ) : (
            <p className="mt-2 text-red-600 font-semibold">
              Vous n'avez pas encore atteint le seuil de réussite. Vous pouvez
              revoir le contenu ou réessayer le quiz.
            </p>
          )}
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">Recommandations</h2>
          <p>Pour approfondir vos connaissances, nous vous recommandons :</p>
          <ul className="list-disc pl-6">
            <li>
              Revoir les concepts clés liés à la fidélisation des clients.
            </li>
            <li>Explorer des études de cas sur les marques de luxe.</li>
            <li>Pratiquer des scénarios pour perfectionner votre approche.</li>
          </ul>
        </section>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button onClick={onBack} variant="outline">
          Retour au contenu
        </Button>
        {!isQuizPassed && (
          <Button onClick={onRetryQuiz} variant="destructive">
            Réessayer le quiz
          </Button>
        )}
        {isQuizPassed && (
          <Link to={`/dashboard/${userId}`}>
            <Button onClick={onProceed} variant="default">
              Passer au prochain module
            </Button>
          </Link>
        )}
      </CardFooter>
    </Card>
  );
};

export default Summary;
